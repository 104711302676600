<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" sm="5">
        <BaseCard heading="<span class='font-weight-bold comision px-2 py-1'>(Comisión 0%)</span>">
            <div class="mt-0">
                <v-alert
                    text
                    dense
                    color="info"
                    icon="mdi-information"
                    border="left"
                    class="subtitle-2"
                ><b>!Atención!</b> Monto minimo de depositos es de {{monto_inicial}} $.</v-alert>
                <v-alert
                    text
                    dense
                    type="error"
                    border="left"
                    v-if="viewError"
                    class="subtitle-2"
                ><b>!Atención!</b> {{msgError}}.</v-alert>
                <v-alert
                    outlined
                    color="success"
                    v-if="viewSuccess"
                >
                    <div class="subtitle-1"><b>!Atención!</b> Debe realizar el deposito a la Wallet especificada a continuación, una vez realizado el deposito será validado en un plazo entre 2hrs y 24hrs y luego será activado en su balance.</div>
                    <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                    <v-row align="center" no-gutters>
                        <v-col cols="12" sm="9">
                            <div class="py-6 text-sm-h4 font-weight-medium">Monto a depositar:  ${{respDeposito.va_monto}}</div>
                            <span class="py-2 pr-2 subtitle-1 font-weight-medium"><v-icon>mdi-wallet</v-icon> Wallet</span> <span class="recuadro_success px-2 py-2 subtitle-2 font-weight-medium" style="width: fit-content;"><cryptoicon :symbol="`${respDeposito.tp_wallet}`" size="24" class="pr-1" />{{respDeposito.nb_wallet}}</span>
                            <div class="py-3 subtitle-1 font-weight-medium" style="overflow-wrap: anywhere;"> {{respDeposito.nro_wallet}} </div>
                        </v-col>
                        <v-col cols="12" sm="3" class="text-center">
                            <qrcode-vue :value="QRvalue" :size="QRsize" level="H" />
                            <v-btn
                                fab
                                dark
                                small
                                color="success"
                                title="Copiar código de Wallet"
                                @click="copy(respDeposito.nro_wallet)"
                            >
                                <v-icon dark>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-card-text>
                    <v-text-field
                        ref="vaMonto"
                        outlined
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="va_monto"
                        :placeholder="`Mínimo ${monto_inicial}`"
                        :error="viewError"
                        @keyup="viewError=false"
                        v-if="nuevo"
                    ></v-text-field>
                </v-card-text>
            </div>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-success w-100 subtitle-1 font-weight-medium" 
            @click="genDeposito"
            v-if="nuevo"
            :disabled="loadingB"
            data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
            >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingB"></span>
                 Realizar Deposito 
            </button>
            <button 
            type="button" 
            class="btn btn-rounded btn-sm btn-success w-100 subtitle-1 font-weight-medium" 
            @click="nuevoDeposito" 
            v-if="!nuevo"
            >
             Nuevo Deposito
            </button>
        </BaseCard>
      </v-col>
      <v-col cols="12" sm="7">
        <BaseCard heading="Historial de depositos">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0"> 
                <v-data-table
                    dense
                    fixed-header
                    height="350px"
                    :headers="headers"
                    :items="histDepositos"
                    item-key="id"
                    sort-by="fecha"
                    :sort-desc="true"
                    :items-per-page="10"
                    :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                    }"
                    :loading="loading"
                    loading-text="Cargando... Un momento por favor"
                >
                <template v-slot:item.status="{ item }">
                    <v-chip
                        :color="getColor(item.status)"
                        dark
                        class="v-chip-status"
                    >
                        {{ item.status }}
                    </v-chip>
                </template>
                </v-data-table>
            </div>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VsToast from '@vuesimple/vs-toast'
import QrcodeVue from 'qrcode.vue'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Depositos",

    data: () => ({
        usrData: {},
        usrToken: '',
        va_monto: null,
        monto_inicial: 0,
        viewError: false,
        msgError: '',
        viewSuccess: false,
        loading: true,
        respDeposito: {
            tp_wallet: 'USDT',
            nb_wallet: 'USDT TRC20',
            nro_wallet: ''
        },
        headers: [
            { text: 'Fecha', value: 'fecha', width: 140 },
            { text: 'Valor', value: 'valor' },
            { text: 'Formato', value: 'formato', width: 115 },
            { text: 'Descripción', value: 'descripcion' },
            { text: 'Status', value: 'status', align: 'center' },
        ],
        histDepositos: [],
        loadingB: false, 
        nuevo: true,
        QRvalue: '',
        QRsize: 100,
        dialog: false
    }),
    
    components: {
      QrcodeVue,
      sesionExpiredDialog
    },

    methods: {
         getColor (status) {
            if (status == 'Pendiente') return 'orange'
            else if (status == 'Rechazada') return 'red'
            else return 'green'
        },
        getDepositos() {            
            //Obtener los datos del Transacciones de Inversión
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/transacciones?tp_transaccion=Deposito`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTransaccion = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTransaccion.success){
                    console.error('Transacciones Error: ', respTransaccion)
                }
                else {
                    this.histDepositos = respTransaccion.data.map((trans) => {
                        return {
                            id: trans.id,
                            tipo: trans.tp_transaccion,
                            valor: trans.va_valor,
                            formato: trans.walletmaster ? trans.walletmaster.tipo_wallet_master.nb_tipo_wallet : 'N/A',
                            descripcion: trans.tx_descripcion,
                            fecha: moment(trans.created_at).format('YYYY-MM-DD HH:mm'),
                            status: trans.tx_status
                        }
                    })
                    //.Transacciones.toLocaleString("es-VE")
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Transacciones Error: ', error)
                this.loading = false;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
                //let message = !error.response ? error.message : error.response.data.msg;
            })
        },
        genDeposito() {
            if(!this.checkMonto()) return
            this.loadingB = true
            //Obtener los datos del Calculadora de Inversión
            axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/users/settransacciones?tp_transaccion=Deposito`,
                params: {
                    va_valor: this.va_monto,
                    tx_descripcion: 'Deposito',
                    wallet_users_id: this.usrData.wallet.id_wallet
                },
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respSolDep = response.data
                console.log('Deposito...', respSolDep);
                if (!respSolDep.success){
                    VsToast.show({
                        title: 'Deposito de fondos',
                        message: 'Solicitud de deposito no puede ser procesada.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.msgError = respSolDep.data.msg
                    this.viewError = true
                    this.loadingB = false
                }
                else {
                    console.log('Deposito Succes: ', respSolDep)
                    this.viewError= false
                    if(respSolDep.data.walletmaster && respSolDep.data.walletmaster.tipo_wallet_master){
                        this.QRvalue = respSolDep.data.walletmaster.wallet
                        this.respDeposito= {
                            nb_wallet: respSolDep.data.walletmaster.tipo_wallet_master.nb_tipo_wallet,
                            tp_wallet: respSolDep.data.walletmaster.tipo_wallet_master.nb_tipo_wallet.split(' ')[0],
                            nro_wallet: respSolDep.data.walletmaster.wallet,
                            va_monto: Number(respSolDep.data.va_valor).toLocaleString("es-VE")
                        }
                        this.va_monto= null
                        this.viewSuccess= true
                        this.getDepositos()
                    }
                    VsToast.show({
                        title: 'Deposito de fondos',
                        message: 'Deposito de fondos cargada exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    
                    this.nuevo = false
                }
                this.loadingB = false
                //this.$refs.vaMonto.focus()
            })
            .catch(error => {
                this.loading = false;
                this.msgError = (error.response && error.response.data) ? error.response.data.message : error.message
                this.viewError = true
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialog = true
                }
                else {
                    VsToast.show({
                        title: 'Deposito de fondos',
                        message: 'Deposito de fondos no puede ser procesada.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.$refs.vaMonto.focus()
                }
                console.error('Deposito Error: ', error)
                //let message = !error.response ? error.message : error.response.data.msg;

                this.loadingB = false;
            })

        },
        checkMonto() {
            if(this.va_monto < this.monto_inicial){
                this.msgError = `El monto ingresado es inferior al minimo de deposito.`
                this.viewError = true
                this.$refs.vaMonto.focus()
                console.error(this.msgError)

                return false
            }
            
            this.viewError = false
            return true

        },
        
        customSort: function(items, index, isDesc) {
            console.log('index: ', index, isDesc)
            items.sort((a, b) => {
                ///if (index[0]=='date' || index[0]=='fecha') {
                if(Date.parse(a[index]) || Date.parse(b[index]) ){
                    //console.log('a: ', a[index], new Date(a[index]))
                    //console.log('b: ', b[index], new Date(b[index]))
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },
    
        nuevoDeposito () {
            this.nuevo = true
            this.viewSuccess = false
        },
        
        async copy(mytext) {
            var input = document.createElement('input');
            input.setAttribute('value', mytext);
            input.value = mytext;        
            document.body.appendChild(input);
            try {
                input.select();    
                input.click();     
                input.focus();
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                if(successful){
                    VsToast.show({
                        //title: this.titleRechazar,
                        message: 'Texto copiado...',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                console.log('Testing code was copied ' + successful + ' ' + msg);
                
            } catch (err) {
                console.log('Oops, unable to copy');
            }
            document.body.removeChild(input);          
        },
    },
  
  async created () {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        let appConfig = await JSON.parse(localStorage.getItem("vars"))
        this.usrToken = localStorage.getItem("usrToken")
        //this.monto_inicial = Number(process.env.VUE_APP_MONTO_MIN_DEPOSITO)
        this.monto_inicial = Number(appConfig.va_min_deposito)
        this.getDepositos()
  },
};
</script>

<style>

/* .success_text input, .success_text fieldset {
    font-size: 20px;
} */

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.comision{
    background-color: #cfecfe;
    border-color: #cfecfe;
    color:#398bf7!important

}

.recuadro_success{
    background-color: #e6f8fa;
    color: #21c1d6!important

}
.v-chip-status {
    border-radius: 20px !important;
    font-size: 12px !important;
    height: 24px !important;
}
</style>